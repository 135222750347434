import { serializeTokens } from './tokens'
import { SerializedFarmConfig } from './types'
import { CHAIN_ID } from './networks'

const serializedTokens = serializeTokens()

const farms: SerializedFarmConfig[] = [
  /**
   * These 3 farms (PID 0, 2, 3) should always be at the top of the file.
   */
   {
    pid: 0,
    v1pid: 0,
    lpSymbol: 'TATER',
    lpAddresses: {
      46729: '0xfdA0F4361258dFD4Cc7f936285874605e46a490D',
      78887: '0x9A4C7dEC4Fa9Fd1dA5D646b3F26f925b750E2392',
    },
    token: serializedTokens.syrup,
    quoteToken: serializedTokens.wlung,
  },
  {
    pid: 2,
    // v1pid: 1,
    lpSymbol: 'TATER-LUNG LP',
    lpAddresses: {
      46729: '0x04a87d67A487C38089736C738aEBc13D6B981c76',
      78887: '0xf74B3F14c6fa68EEaA9cF57Fb32Eb2B7CE939935',
    },
    token: serializedTokens.tater,
    quoteToken: serializedTokens.wlung,
  },
  // {
  //   pid: 3,
  //   // v1pid: 2,
  //   lpSymbol: 'BUSD-LUNG LP',
  //   lpAddresses: {
  //     46729: '0xc8EdeC730CC6aBb57c213c840633071164357357',
  //     78887: '0xab83C7AcA918f2a72De6d05C403441802d4dD9CB',
  //   },
  //   token: serializedTokens.nusd,
  //   quoteToken: serializedTokens.wlung,
  // },
  //    * V3 by order of release (some may be out of PID order due to multiplier boost)
  {
    pid: 4,
    // v1pid: 3,
    lpSymbol: 'USDT-LUNG LP',
    lpAddresses: {
      46729: '0x0DC8b02c393B3FD37dA5B3944Ad9d301377C5849',
      78887: '0x95451048d4E96Cb710A2aC2Cc2D760fcC67654a5',
    },
    token: serializedTokens.nusdt,
    quoteToken: serializedTokens.wlung,
  },
  // {
  //   pid: 5,
  //   lpSymbol: 'ETH-LUNG LP',
  //   lpAddresses: {
  //     46729: '',
  //     78887: '0xD30916af2D1E8F5da477057A95958e724528bcCa',
  //   },
  //   token: serializedTokens.eth,
  //   quoteToken: serializedTokens.wlung,
  // },


  
].filter((f) => !!f.lpAddresses[CHAIN_ID])

export default farms
